import { 
    serializeInputs,
    serializeProducts,
    serializeBundles,
    serializeBundleGroups,
    serializeCollectionGroups
} from "./form";

/* BUNDLES */
export const getBundleList = async (params = {}) => {
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles?${query}`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    let data = await res.json()
    data.status = res.status
    return data?.bundles ? data.bundles : data
}

export const getCrossLocaleBundleList = async (params = {}) => {
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/cross-locale?${query}`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    let data = await res.json()
    data.status = res.status
    return data?.bundles ? data.bundles : data
}

export const createNewBundle = async (inputs) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/new`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            view_id: inputs.locales.join(';'),
            // name: inputs.bundleName,
            name: inputs.bundleId,
            // description: inputs.bundleDescription,
            image: inputs.bundleImage,
            activity: inputs.bundleActivity,
            accessories_bundle: inputs.bundleAccessories,
            gender: inputs.bundleGender,
            products: serializeProducts(inputs),
            // price: inputs.bundlePrice,
            discount: inputs.bundleDiscount,
            start_at: inputs.bundleStartDate,
            end_at: inputs.bundleEndDate,
            published: inputs.bundlePublished,
            translations: Object.keys(inputs).reduce((acc,key) => {
                if(key.includes('bundleName-')){
                    acc[key.replace("bundleName-","")] = inputs[key]
                }
                return acc
            },{})
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const importCrossBundlesFromFile = async (data) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/import`, {
        method: 'POST',
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: data,
    })
    let resData = await res.json()
    resData.status = res.status
    return resData 
}

export const getBundleInputs = async (id, view_id) => {
    let data = await getBundleList({ id, view_id })
    return !data.status ? (Object.keys(data).length ? {
        locales: [data[0].view_id],
        bundleId: data[0].generic_name,
        bundleName: data[0].name,
        bundleDescription: data[0].description,
        bundleImage: data[0].image,
        bundleActivity: data[0].activity,
        bundleAccessories: data[0].accessories_bundle,
        bundleGender: data[0].gender,
        bundlePrice: data[0].price,
        bundleDiscount: data[0].discount,
        bundleStartDate: new Date(data[0].start_at).toISOString().split('T')[0],
        bundleEndDate: new Date(data[0].end_at).toISOString().split('T')[0],
        bundleUpdatedAt: data[0].updated_at,
        bundleUpdatedBy: data[0].updated_by,
        bundlePublished: data[0].published,
        ...(await serializeInputs(JSON.parse(data[0].products), JSON.parse(data[0].data)))
    } : null) : data
}

export const getCrossLocaleBundleInputs = async (id) => {
    let data = await getCrossLocaleBundleList({ id })
    return !data.status ? (Object.keys(data).length ? {
        locales: data[0].view_id.split(';'),
        bundleId: data[0].name,
        bundleDescription: data[0].description,
        bundleImage: data[0].image,
        bundleActivity: data[0].activity,
        bundleAccessories: data[0].accessories_bundle,
        bundleGender: data[0].gender,
        bundlePrice: data[0].price,
        bundleDiscount: data[0].discount,
        bundleStartDate: new Date(data[0].start_at).toISOString().split('T')[0],
        bundleEndDate: new Date(data[0].end_at).toISOString().split('T')[0],
        bundleUpdatedAt: data[0].updated_at,
        bundleUpdatedBy: data[0].updated_by,
        bundlePublished: data[0].published,
        ...(await serializeInputs(JSON.parse(data[0].products), JSON.parse(data[0].data))),
        ...(Object.keys(data[0]?.translations).reduce((acc,view_id) =>{
            acc['bundleName-' + view_id] = data[0].translations[view_id]
            return acc
        },{})),
    } : null) : data
}

export const updateBundle = async (inputs) => {
    serializeProducts(inputs)
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/edit`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            id: inputs.id,
            view_id: inputs.view_id,
            name: inputs.bundleName,
            description: inputs.bundleDescription,
            image: inputs.bundleImage,
            activity: inputs.bundleActivity,
            accessories_bundle: inputs.bundleAccessories,
            gender: inputs.bundleGender,
            products: serializeProducts(inputs),
            // price: inputs.bundlePrice,
            discount: inputs.bundleDiscount,
            start_at: inputs.bundleStartDate,
            end_at: inputs.bundleEndDate,
            published: inputs.bundlePublished
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const updateCrossLocaleBundle = async (inputs) => {
    serializeProducts(inputs)
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/cross-locale/edit`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            id: inputs.id,
            view_id: inputs.locales.join(';'),
            name: inputs.bundleId,
            description: inputs.bundleDescription,
            image: inputs.bundleImage,
            activity: inputs.bundleActivity,
            accessories_bundle: inputs.bundleAccessories,
            gender: inputs.bundleGender,
            products: serializeProducts(inputs),
            discount: inputs.bundleDiscount,
            start_at: inputs.bundleStartDate,
            end_at: inputs.bundleEndDate,
            published: inputs.bundlePublished,
            translations: Object.keys(inputs).reduce((acc,key) => {
                if(key.includes('bundleName-')){
                    acc[key.replace("bundleName-","")] = inputs[key]
                }
                return acc
            },{})
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const deleteBundle = async (id, view_id) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({ id, view_id })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const deleteCrossLocaleBundle = async (id) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/cross-locale/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({ id })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const updateBundlesOrder = async (bundles) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/cross-locale/order`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body:  JSON.stringify({bundles})
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const getBundleStatusData = async (params = {}) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    let data = await res.json()
    data.status = res.status
    return data?.bundles ? data.bundles : data
}

export const getBundleExport = async (timestamp) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/export?t=${timestamp}`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    return res
}

/* COLLECTION GROUP */
export const getCollectionGroupList = async (params = {}) => {
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection-group?${query}`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    let data = await res.json()
    data.status = res.status
    return data?.collections ? data.collections : data
}

export const getCrossLocaleCollectionGroupList = async (params = {}) => {
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection-group/cross-locale?${query}`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    let data = await res.json()
    data.status = res.status
    return data?.collections ? data.collections : data
}

export const createNewCollectionGroup = async (inputs) => {
    const crossLocaleBundles = await getCrossLocaleBundleList(inputs.id);
    const bundles = await getBundleList(inputs.id, inputs.view_id);

    const cleanedBundles = serializeCollectionGroups(inputs, crossLocaleBundles, bundles).map(bundle => {
        const { price, ...cleanedBundle } = bundle;
        return cleanedBundle;
    });

    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection-group/new`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            view_id: inputs.locales.join(';'),
            name: inputs.collectionId,
            bundles: cleanedBundles,
            start_at: inputs.collectionStartDate,
            end_at: inputs.collectionEndDate,
            published: inputs.collectionPublished,
            cta: inputs.collectionCTA,
            associated: inputs.collectionAssociated,
            image: inputs.collectionImage,
            translations: Object.keys(inputs).reduce((acc, key) => {
                const match = key.match(/^(name|description|title)-(.+)$/)
                if (match) {
                    const [, type, locale] = match
                    acc[type] = acc[type] || {}
                    acc[type][locale] = inputs[key]
                }
                return acc
            }, {})
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const getCollectionGroupInputs = async (id, view_id) => {
    let data = await getCollectionGroupList({ id, view_id })
    return !data.status ? (Object.keys(data).length ? {
        locales: [data[0].view_id],
        collectionId: data[0].generic_name,
        collectionName: data[0].name,
        collectionBundle: data[0].bundles,
        collectionImage: data[0].image,
        collectionCTA: data[0].cta,
        collectionTitle: data[0].title,
        collectionDescription: data[0].description,
        collectionAssociated: data[0].associated,
        collectionStartDate: new Date(data[0].start_at).toISOString().split('T')[0],
        collectionEndDate: new Date(data[0].end_at).toISOString().split('T')[0],
        collectionUpdatedAt: data[0].updated_at,
        collectionUpdatedBy: data[0].updated_by,
        collectionPublished: data[0].published,
    } : null) : data
}

export const getCrossLocaleCollectionGroupInputs = async (id) => {
    let data = await getCrossLocaleCollectionGroupList({ id })
    return !data.status ? (Object.keys(data).length ? {
        locales: data[0].view_id.split(';'),
        collectionId: data[0].name,
        collectionBundle: data[0].bundles,
        collectionImage: data[0].image,
        collectionCTA: data[0].cta,
        collectionAssociated: data[0].associated,
        collectionStartDate: new Date(data[0].start_at).toISOString().split('T')[0],
        collectionEndDate: new Date(data[0].end_at).toISOString().split('T')[0],
        collectionUpdatedAt: data[0].updated_at,
        collectionUpdatedBy: data[0].updated_by,
        collectionPublished: data[0].published,
        ...Object.keys(data[0]).reduce((acc, key) => {
            if (key === 'translations') {
                if (data[0][key] != undefined){
                    Object.keys(data[0][key]).forEach(locale => {
                        acc[`name-${locale}`] = data[0][key][locale];
                    });
                }
            } else if (typeof data[0][key] === 'object' && data[0][key] !== null) {
                Object.keys(data[0][key]).forEach(locale => {
                    acc[`${key}-${locale}`] = data[0][key][locale];
                });
            }
            return acc;
        }, {})
    } : null) : data
}

export const updateCollectionGroup = async (inputs) => {
    const crossLocaleBundles = await getCrossLocaleBundleList(inputs.id)
    const bundles = await getBundleList(inputs.id, inputs.view_id);

    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection-group/edit`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            id: inputs.id,
            view_id: inputs.view_id,
            name: inputs.collectionName,
            image: inputs.collectionImage,
            bundles: serializeCollectionGroups(inputs, crossLocaleBundles, bundles),
            cta: inputs.collectionCTA,
            associated: inputs.collectionAssociated,
            start_at: inputs.collectionStartDate,
            end_at: inputs.collectionEndDate,
            description: inputs.collectionDescription,
            title: inputs.collectionTitle,
            published: inputs.collectionPublished
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const updateCrossLocaleCollectionGroup = async (inputs) => {
    const crossLocaleBundles = await getCrossLocaleBundleList(inputs.id);
    const bundles = await getBundleList(inputs.id, inputs.view_id);

    serializeBundles(inputs, inputs.bundles)
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection-group/cross-locale/edit`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            id: inputs.id,
            view_id: inputs.locales.join(';'),
            name: inputs.collectionId,
            image: inputs.collectionImage,
            bundles: serializeCollectionGroups(inputs, crossLocaleBundles, bundles),
            cta: inputs.collectionCTA,
            associated: inputs.collectionAssociated,
            start_at: inputs.collectionStartDate,
            end_at: inputs.collectionEndDate,
            published: inputs.collectionPublished,
            translations: Object.keys(inputs).reduce((acc, key) => {
                const match = key.match(/^(name|description|title)-(.+)$/)
                if (match) {
                    const [, type, locale] = match
                    acc[type] = acc[type] || {}
                    acc[type][locale] = inputs[key]
                }
                return acc
            }, {})
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const deleteCollectionGroup = async (id, view_id) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection-group/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({ id, view_id })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const deleteCrossLocaleCollectionGroup = async (id) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection-group/cross-locale/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({ id })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const updateCollectionsOrder = async (collections) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection-group/cross-locale/order`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body:  JSON.stringify({collections})
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const getCollectionStatusData = async (params = {}) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection-group`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    let data = await res.json()
    data.status = res.status
    return data?.collections ? data.collections : data
}

/* COLLECTION */
export const getCrossLocaleCollectionList = async (params = {}) => {
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection/cross-locale?${query}`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    let data = await res.json()
    data.status = res.status
    return data?.collections ? data.collections : data
}

export const createNewCollection = async (inputs) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection/new`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            view_id: inputs.locales.join(';'),
            name: inputs.collectionId,
            translations: Object.keys(inputs).reduce((acc, key) => {
                const match = key.match(/^(name)-(.+)$/)
                if (match) {
                    const [, type, locale] = match
                    acc[type] = acc[type] || {}
                    acc[type][locale] = inputs[key]
                }
                return acc
            }, {})
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const getCrossLocaleCollectionInputs = async (id) => {
    let data = await getCrossLocaleCollectionList({ id })
    return !data.status ? (Object.keys(data).length ? {
        locales: data[0].view_id.split(';'),
        collectionId: data[0].generic_name,
        collectionUpdatedAt: data[0].updated_at,
        collectionUpdatedBy: data[0].updated_by,
        ...Object.keys(data[0]).reduce((acc, key) => {
            if (key === 'translations') {
                Object.keys(data[0][key]).forEach(locale => {
                    acc[`name-${locale}`] = data[0][key][locale];
                });
            }
            return acc;
        }, {})
    } : null) : data
}

export const updateCrossLocaleCollection = async (inputs) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection/cross-locale/edit`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            id: inputs.id,
            view_id: inputs.locales.join(';'),
            name: inputs.collectionId,
            translations: Object.keys(inputs).reduce((acc, key) => {
                const match = key.match(/^(name)-(.+)$/)
                if (match) {
                    const [, type, locale] = match
                    acc[type] = acc[type] || {}
                    acc[type][locale] = inputs[key]
                }
                return acc
            }, {})
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const deleteCollection = async (id, view_id) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({ id, view_id })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const deleteCrossLocaleCollection = async (id) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/collection/cross-locale/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({ id })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

/* BUNDLE GROUPS */
export const getBundleGroupList = async (params = {}) => {
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundle-group?${query}`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    let data = await res.json()
    data.status = res.status
    return data?.bundleGroups ? data.bundleGroups : data
}

export const getCrossLocaleBundleGroupList = async (params = {}) => {
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundle-group/cross-locale?${query}`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    let data = await res.json()
    data.status = res.status
    return data?.bundleGroups ? data.bundleGroups : data
}

export const createNewBundleGroup = async (inputs) => {
    const crossLocaleBundles = await getCrossLocaleBundleList(inputs.id);
    const bundles = await getBundleList(inputs.id, inputs.view_id);

    const cleanedBundles = serializeBundleGroups(inputs, crossLocaleBundles, bundles).map(bundle => {
        const { price, ...cleanedBundle } = bundle;
        return cleanedBundle;
    });

    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundle-group/new`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            view_id: inputs.locales.join(';'),
            name: inputs.bundleGroupId,
            bundles: cleanedBundles,
            start_at: inputs.bundleGroupStartDate,
            end_at: inputs.bundleGroupEndDate,
            published: inputs.bundleGroupPublished,
            image: inputs.bundleGroupImage,
            translations: Object.keys(inputs).reduce((acc, key) => {
                const match = key.match(/^(name|description|tag|title)-(.+)$/)
                if (match) {
                    const [, type, locale] = match
                    acc[type] = acc[type] || {}
                    acc[type][locale] = inputs[key]
                }
                return acc
            }, {})
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const getBundleGroupInputs = async (id, view_id) => {
    let data = await getBundleGroupList({ id, view_id })
    return !data.status ? (Object.keys(data).length ? {
        locales: [data[0].view_id],
        bundleGroupId: data[0].generic_name,
        bundleGroupName: data[0].name,
        bundleGroupBundle: data[0].bundles,
        bundleGroupImage: data[0].image,
        bundleGroupTitle: data[0].title,
        bundleGroupDescription: data[0].description,
        bundleGroupTag: data[0].tag,
        bundleGroupStartDate: new Date(data[0].start_at).toISOString().split('T')[0],
        bundleGroupEndDate: new Date(data[0].end_at).toISOString().split('T')[0],
        bundleGroupUpdatedAt: data[0].updated_at,
        bundleGroupUpdatedBy: data[0].updated_by,
        bundleGroupPublished: data[0].published,
    } : null) : data
}

export const getCrossLocaleBundleGroupInputs = async (id) => {
    let data = await getCrossLocaleBundleGroupList({ id })
    return !data.status ? (Object.keys(data).length ? {
        locales: data[0].view_id.split(';'),
        bundleGroupId: data[0].name,
        bundleGroupBundle: data[0].bundles,
        bundleGroupImage: data[0].image,
        bundleGroupStartDate: new Date(data[0].start_at).toISOString().split('T')[0],
        bundleGroupEndDate: new Date(data[0].end_at).toISOString().split('T')[0],
        bundleGroupUpdatedAt: data[0].updated_at,
        bundleGroupUpdatedBy: data[0].updated_by,
        bundleGroupPublished: data[0].published,
        ...Object.keys(data[0]).reduce((acc, key) => {
            if (key === 'translations') {
                Object.keys(data[0][key]).forEach(locale => {
                    acc[`name-${locale}`] = data[0][key][locale];
                });
            } else if (typeof data[0][key] === 'object' && data[0][key] !== null) {
                Object.keys(data[0][key]).forEach(locale => {
                    acc[`${key}-${locale}`] = data[0][key][locale];
                });
            }
            return acc;
        }, {})
    } : null) : data
}

export const updateBundleGroup = async (inputs) => {
    const crossLocaleBundles = await getCrossLocaleBundleList(inputs.id)
    const bundles = await getBundleList(inputs.id, inputs.view_id);

    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundle-group/edit`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            id: inputs.id,
            view_id: inputs.view_id,
            name: inputs.bundleGroupName,
            image: inputs.bundleGroupImage,
            bundles: serializeBundleGroups(inputs, crossLocaleBundles, bundles),
            start_at: inputs.bundleGroupStartDate,
            end_at: inputs.bundleGroupEndDate,
            description: inputs.bundleGroupDescription,
            tag: inputs.bundleGroupTag,
            title: inputs.bundleGroupTitle,
            published: inputs.bundleGroupPublished
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const updateCrossLocaleBundleGroup = async (inputs) => {
    const crossLocaleBundles = await getCrossLocaleBundleList(inputs.id);
    const bundles = await getBundleList(inputs.id, inputs.view_id);

    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundle-group/cross-locale/edit`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            id: inputs.id,
            view_id: inputs.locales.join(';'),
            name: inputs.bundleGroupId,
            image: inputs.bundleGroupImage,
            bundles: serializeBundleGroups(inputs, crossLocaleBundles, bundles),
            start_at: inputs.bundleGroupStartDate,
            end_at: inputs.bundleGroupEndDate,
            published: inputs.bundleGroupPublished,
            translations: Object.keys(inputs).reduce((acc, key) => {
                const match = key.match(/^(name|description|tag|title)-(.+)$/)
                if (match) {
                    const [, type, locale] = match
                    acc[type] = acc[type] || {}
                    acc[type][locale] = inputs[key]
                }
                return acc
            }, {})
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const deleteBundleGroup = async (id, view_id) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundle-group/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({ id, view_id })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const deleteCrossLocaleBundleGroup = async (id) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundle-group/cross-locale/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({ id })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const updateBundleGroupOrder = async (bundleGroups) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundle-group/cross-locale/order`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body:  JSON.stringify({bundleGroups})
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const getBundleGroupStatusData = async (params = {}) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundle-group`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    let data = await res.json()
    data.status = res.status
    return data?.bundleGroups ? data.bundleGroups : data
}

/* OTHERS */
export const loginUser = async (credentials) => {
    return fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

export const validateToken = async () => {
    const token = localStorage.getItem('accessToken').replace(/['"]+/g, '')
    if(!token) return false
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/validateToken`, {
        method: 'GET',
        headers: {
            'access-token': token
        },
    })
    let data = await res.json()
    if(data.validToken) return true
    else return false
}

export const updateOrder = async (orderedItems) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/update-order`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify(orderedItems)
    })
    let data = await res.json()
    data.status = res.status
    return data
};