import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    createNewBundleGroup,
    getBundleGroupInputs,
    getCrossLocaleBundleGroupInputs,
    updateBundleGroup,
    updateCrossLocaleBundleGroup,
    getCrossLocaleBundleList
} from "@/utils/admin/fetch";
import Error404 from "@/pages/404";

import { LOCALES } from "@/const";
import Alert from "@/components/admin/Alert";
import { validateBundleGroupInputs, getUpdatedAtTimeString } from "@/utils/admin/form";
import { handleLogout } from "@/utils/admin/logout";
import { validateToken } from "@/utils/admin/fetch";

import * as Tooltip from '@radix-ui/react-tooltip';
import '../../../styles/tooltip.css';
import { ReactComponent as QuestionMarkIcon } from '@/assets/svg/questionMark.svg'

import { useTranslation } from "react-i18next";
import "../../../i18n";

const BundleGroupForm = (props) => {
    const [inputs, setInputs] = useState({
        collectionImage: {
            style: "left",
            url: ''
        }
    })
    const [bundles, setBundles] = useState([])
    const [locale, setLocale] = useState("")
    const [loading, setLoading] = useState(true)
    const { key } = useParams()
    const [id, view_id] = key?.split("-") || []
    const [selectedBundlesCount, setSelectedBundlesCount] = useState(0)
    const [translations, setTranslations] = useState({});

    const [alert, setAlert] = useState(null)
    const [popupContent, setPopupContent] = useState(null)

    const [notFound, setNotFound] = useState(false)
    const [translationPopUpShown, setTranslationPopUpShown] = useState(false)
    const [waitingResponse, setWaitingResponse] = useState(false)

    const { t } = useTranslation("translation");

    useEffect(() => {
        validateToken()
            .then(res => {
                if (!res) handleLogout()
            })
    }, [])

    useEffect(() => {
        if (props.edit || props.duplicate) {
            const getBundleGroupInputsThen = (res) => {
                if (res) {
                    if (typeof res.bundleGroupBundle === 'string') {
                        try {
                            res.bundleGroupBundle = JSON.parse(res.bundleGroupBundle);
                        } catch (error) {
                            res.bundleGroupBundle = [];
                        }
                    }
                    setInputs({
                        ...res,
                        bundleGroupImage: res.bundleGroupImage || { url: '', style: 'left' }
                    });
                } else {
                    setNotFound(true)
                }
            }
            if (props.multiLocale) {
                getCrossLocaleBundleGroupInputs(id).then(getBundleGroupInputsThen)
            } else {
                getBundleGroupInputs(id, view_id).then(getBundleGroupInputsThen)
            }
        }
    }, [id, props.duplicate, props.edit, props.multiLocale, view_id])

    useEffect(() => {
        if (props.create) {
            setInputs((prevInputs) => ({
                ...prevInputs,
                bundleGroupImage: {
                    ...prevInputs.bundleGroupImage,
                    style: prevInputs.bundleGroupImage?.style || "left"
                }
            }))
        }
    }, [props.create])

    useEffect(() => {
        getCrossLocaleBundleList({ view_id: locale }).then((res) => {
          if (res.status !== 401) {
            setLoading(true);
            setTimeout(function () {
              setBundles(res);
              setLoading(false);
            }, 250);
          } else handleLogout();
        });
      }, [locale]);
    
      useEffect(() => {
        setSelectedBundlesCount(inputs.bundleGroupBundle?.length || 0)
    }, [inputs.bundleGroupBundle])

    const handleChange = ({ target }) => {
        const name = target.name
        const value = target.type === "checkbox" ? target.checked : target.value
    
        if (name.startsWith("bundleGroupImage.")) {
            const key = name.split('.')[1]
            setInputs((values) => ({
                ...values,
                bundleGroupImage: {
                    ...values.bundleGroupImage,
                    [key]: value
                }
            }))
        } else {
            setInputs((values) => ({ ...values, [name]: value }))
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (validateBundleGroupInputs(inputs)) {
            setWaitingResponse(true);

            // Title for bundle group are not required
            if (props.multiLocale && false) {
                const titleKeys = Object.keys(inputs).filter(key => key.startsWith('title-'));
                if (titleKeys.length === 0 || titleKeys.some(key => !inputs[key])) {
                    handleAlert( {message: "At least a Title are required" });
                    return;
                }
            }

            if (props.edit) {
                const updateBundleGroupThen = (res) => {
                    if (res.status !== 401) {
                        setWaitingResponse(false);
                        handleAlert(res);
                    } else handleLogout();
                }

                if (props.multiLocale)
                    updateCrossLocaleBundleGroup({ ...inputs, id, view_id, bundles }).then(updateBundleGroupThen);
                else
                    updateBundleGroup({ ...inputs, id, view_id, bundles }).then(updateBundleGroupThen);
            }
            if (props.create || props.duplicate) {
                createNewBundleGroup(inputs, bundles).then((res) => { 
                    if (res.status !== 401) {
                        setWaitingResponse(false);
                        handleAlert(res);
                    } else handleLogout();
                });
            }
        } else {
            setAlert({
                shown: true,
                error: true,
                title: t("form.input-not-valid"),
                actions: {
                    primary: {
                        callback: () => setAlert({ ...alert, shown: false }),
                        title: t("table.alert.close"),
                    },
                },
            });
        }
    };

    const handleAlert = (res) => {
        const statusText = res.status ? `(${res.status})` : '';
        const titleText = `${t("form.saving-error")} ${statusText}`;

        if (res.status !== 200)
            setAlert({
                shown: true,
                error: true,
                message: res.message,
                title: titleText,
                actions: {
                    primary: {
                        callback: () => setAlert({ ...alert, shown: false }),
                        title: t("table.alert.close"),
                    },
                },
            });
        else
            setAlert({
                shown: true,
                error: false,
                title: t("form.update-saved"),
                message: t("form.update-bundle-group-saved-detail"),
                actions: {
                    primary: {
                        callback: () => (window.location.href = "/"),
                        title: t("form.back-to-list"),
                    },
                },
            });
    };

    const getCTAText = () => {
        if (props.edit) return t("form.edit");
        if (props.duplicate) return t("form.create-duplicate");
        else return t("form.create");
    };

    const getCTATextClass = () => {
        return waitingResponse ? "invisible" : "";
    };

    const toggleSelectAll = event => {
        event.preventDefault();
        if (inputs.locales?.length !== LOCALES.length)
            setInputs({ ...inputs, locales: LOCALES })
        else {
            setInputs({ ...inputs, locales: [] })
        }
    }

    const toggleInputsBundle = newBundle => {
        let bundleArray = inputs.bundleGroupBundle || [];
        const bundleExists = bundleArray.some(bundle => bundle.id === newBundle.id);
    
        if (bundleExists) {
            bundleArray = bundleArray.filter(bundle => bundle.id !== newBundle.id);
        } else {
            bundleArray.push(newBundle);
        }
    
        setInputs(prevInputs => {
            const updatedInputs = { ...prevInputs, bundleGroupBundle: bundleArray };
            return updatedInputs;
        });
    };

    const handleLocaleSelection = event => {
        if (inputs.locales?.length) {
            if (inputs.locales?.includes(event.target.value)) {
                setInputs({ ...inputs, locales: inputs.locales?.filter(l => l !== event.target.value) })
            }
            else {
                setInputs({ ...inputs, locales: [...inputs?.locales, event.target.value] })
            }
        } else setInputs({ ...inputs, locales: [event.target.value] })

    }

    const openTranslationPopUp = (event, contentType) => {
        event.preventDefault()
        const newTranslations = Object.keys(inputs).reduce((acc, key) => {
            if (key.includes(`${contentType}-`)) {
                acc[key] = inputs[key]
            }
            return acc
        }, {})
        setPopupContent(contentType)
        setTranslations(newTranslations)
        setTranslationPopUpShown(true)
    }

    const bundleGroupType = props.multiLocale ? t("form.cross-locale-bundle-group") : t("form.bundle-group")

    return !notFound ? (
        <div className="page-content">
            <Alert
                title={alert?.title}
                message={alert?.message}
                error={alert?.error}
                shown={alert?.shown}
                actions={alert?.actions}
            />

            <form onSubmit={handleSubmit} className="container mx-auto">
                {(props.multiLocale && translationPopUpShown) &&
                    <TranslationPopUp
                        locales={inputs.locales}
                        translations={translations}
                        saveTranslations={(data) => {
                            setInputs(prevInputs => ({ ...prevInputs, ...data }))
                            setTranslationPopUpShown(false)
                        }}
                        closePopUp={() => setTranslationPopUpShown(false)}
                        contentType={popupContent}
                    />
                }

                <div className="divide-y divide-gray-200">
                    <div>
                        <div className="mb-12">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {getCTAText()} {t("form.bundle-group")}
                            </h3>
                            <p className="mt-1 max-w-2.5xl text-sm text-gray-500">
                                {props.create ? t("form.create-bundle-group-text") : props.multiLocale ? t("form.cross-locale-update-text") : t("form.update-text")}
                                <b className="block mt-2">{t("form.bundle-group-important-note")}</b>
                            </p>
                            <p className="mt-2.5 italic max-w-2.5xl text-sm text-gray-500">
                                {t("form.mandatory-information")}
                            </p>
                        </div>

                        <div className="mb-5">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 mt-5">
                                {bundleGroupType} {t("form.details")}
                            </h3>
                            <p className="mt-1 max-w-2.5xl text-sm text-gray-500">
                                {t("form.info-text")}
                            </p>
                        </div>

                        <div className="group bg-gray-100 px-8">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-5">
                                <label
                                    htmlFor="bundleGroupLocale"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.locale-bundle-group")} <span className="text-lg">*</span>
                                </label>
                                <div className={`mt-1 sm:mt-0 sm:col-span-2 flex flex-wrap gap-5${props.edit && !props.multiLocale ? ' opacity-50 pointer-events-none' : ''}`}>
                                    {LOCALES.map((locale, index) => (
                                        <div key={index} className="flex grow-0 items-center text-sm text-gray-700">
                                            <input id={`cb-${locale}`}
                                                type="checkbox"
                                                value={locale}
                                                onChange={handleLocaleSelection}
                                                checked={inputs.locales?.includes(locale) || false}
                                                className="w-4 h-4 text-black bg-white border-gray-300 focus:ring-black dark:focus:ring-black focus:ring-2" />
                                            <label htmlFor={`cb-${locale}`} className="ml-2 text-sm font-medium">{locale}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {props.multiLocale && <div className="flex pb-5">
                                <button className="text-sm font-semibold p-2 bg-gray-200" onClick={toggleSelectAll}>{inputs.locales?.length === LOCALES.length ? t("form.deselect-all") : t("form.select-all")}</button>
                            </div>}

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="bundleGroupId"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.bundle-group-id")} <span className="text-lg">*</span> <small>(Backend)</small>
                                    <Tooltip.Provider>
                                        <Tooltip.Root>
                                            <Tooltip.Trigger asChild>
                                                <button className="IconButton bg-gray-500 flex" style={{ flex: "0 0 25px" }} onClick={event => event.preventDefault()}>
                                                    <QuestionMarkIcon />
                                                </button>
                                            </Tooltip.Trigger>
                                            <Tooltip.Portal>
                                                <Tooltip.Content className="TooltipContent" sideOffset={5} side="bottom">
                                                    <div className="text-xs" dangerouslySetInnerHTML={{ __html: t("form.bundle-group-name-tooltip") }}></div>
                                                    <Tooltip.Arrow className="TooltipArrow" />
                                                </Tooltip.Content>
                                            </Tooltip.Portal>
                                        </Tooltip.Root>
                                    </Tooltip.Provider>
                                </label>

                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        disabled={!props.multiLocale}
                                        required
                                        type="text"
                                        name="bundleGroupId"
                                        id="bundleGroupId"
                                        autoComplete="bundleGroupId"
                                        placeholder={t("form.bundle-group-name-placeholder")}
                                        value={inputs.bundleGroupId || ""}
                                        onChange={handleChange}
                                        className={`flex-1 block w-full focus:ring-black focus:border-black min-w-0  sm:text-sm border-gray-300 p-3 ${!props.multiLocale && 'opacity-50'}`}
                                    />
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label htmlFor="bundleGroupImageStyle" className="block text-sm font-medium text-gray-700">
                                    {t("form.bundle-group-image-style")}
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <select
                                        id="bundleGroupImageStyle"
                                        name="bundleGroupImage.style"
                                        value={inputs.bundleGroupImage?.style || "left"}
                                        onChange={handleChange}
                                        className="mt-1 block w-full text-base border-gray-300 focus:outline-none focus:ring-black focus:border-black sm:text-sm p-3"
                                    >
                                        <option value="left">{t("form.bundle-group-image-style-left")}</option>
                                        <option value="right">{t("form.bundle-group-image-style-right")}</option>
                                        <option value="landscape">{t("form.bundle-group-image-style-landscape")}</option>
                                    </select>
                                </div>
                                <label
                                    htmlFor="bundleGroupImageUrl"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.bundle-group-image")} <span className="text-lg">*</span>
                                    <Tooltip.Provider>
                                        <Tooltip.Root>
                                            <Tooltip.Trigger asChild>
                                                <button className="IconButton bg-gray-500 flex" style={{ flex: "0 0 25px" }} onClick={event => event.preventDefault()}>
                                                    <QuestionMarkIcon />
                                                </button>
                                            </Tooltip.Trigger>
                                            <Tooltip.Portal>
                                                <Tooltip.Content className="TooltipContent" sideOffset={5} side="bottom">
                                                    <div className="text-xs" dangerouslySetInnerHTML={{ __html: t("form.bundle-group-image-tooltip") }}></div>
                                                    <Tooltip.Arrow className="TooltipArrow" />
                                                </Tooltip.Content>
                                            </Tooltip.Portal>
                                        </Tooltip.Root>
                                    </Tooltip.Provider>
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        required
                                        type="text"
                                        name="bundleGroupImage.url"
                                        id="bundleGroupImageUrl"
                                        value={inputs.bundleGroupImage?.url || ""}
                                        onChange={handleChange}
                                        placeholder={t("form.bundle-group-image-placeholder")}
                                        className="flex-1 block w-full focus:ring-black focus:border-black min-w-0 sm:text-sm border-gray-300 p-3"
                                    />
                                </div>
                            </div>

                            {!props.multiLocale && <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="bundleGroupTag"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.bundle-group-tag")}
                                </label>

                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        type="text"
                                        name="bundleGroupTag"
                                        id="bundleGroupTag"
                                        autoComplete="bundleGroupTag"
                                        placeholder={t("form.bundle-group-tag-placeholder")}
                                        value={inputs.bundleGroupTag || ""}
                                        onChange={handleChange}
                                        className="flex-1 block w-full focus:ring-black focus:border-black min-w-0  sm:text-sm border-gray-300 p-3"
                                    />
                                </div>
                            </div>}

                            {props.multiLocale && <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    {t('form.add-bundle-group-tag-translations')} <small>(Frontend)</small>
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    {props.multiLocale && <button
                                        className={`text-sm font-semibold mt-2 ${!inputs.locales?.length ? 'opacity-50' : 'hover:opacity-50 duration-200 cursor-pointer'}`}
                                        disabled={!inputs.locales?.length}
                                        onClick={(event) => openTranslationPopUp(event, 'tag')}>
                                            + {t('form.add-bundle-group-tag-translations-cta')}
                                    </button>}
                                </div>
                            </div>}

                            {!props.multiLocale && <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="bundleGroupTitle"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.bundle-group-title")} <span className="text-lg">*</span>
                                </label>

                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        required
                                        type="text"
                                        name="bundleGroupTitle"
                                        id="bundleGroupTitle"
                                        autoComplete="bundleGroupTitle"
                                        placeholder={t("form.bundle-group-title-placeholder")}
                                        value={inputs.bundleGroupTitle || ""}
                                        onChange={handleChange}
                                        className="flex-1 block w-full focus:ring-black focus:border-black min-w-0  sm:text-sm border-gray-300 p-3"
                                    />
                                </div>
                            </div>}

                            {props.multiLocale && <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    {t('form.add-bundle-group-title-translations')} <small>(Frontend)</small>
                                    <span className="text-lg">*</span>
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    {props.multiLocale && <button
                                        className={`text-sm font-semibold mt-2 ${!inputs.locales?.length ? 'opacity-50' : 'hover:opacity-50 duration-200 cursor-pointer'}`}
                                        disabled={!inputs.locales?.length}
                                        onClick={(event) => openTranslationPopUp(event, 'title')}>
                                            + {t('form.add-bundle-group-title-translations-cta')}
                                    </button>}
                                </div>
                            </div>}

                            {!props.multiLocale && <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="bundleGroupDescription"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.bundle-group-description")}
                                </label>

                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        type="text"
                                        name="bundleGroupDescription"
                                        id="bundleGroupDescription"
                                        autoComplete="bundleGroupDescription"
                                        placeholder={t("form.bundle-group-description-placeholder")}
                                        value={inputs.bundleGroupDescription || ""}
                                        onChange={handleChange}
                                        className="flex-1 block w-full focus:ring-black focus:border-black min-w-0  sm:text-sm border-gray-300 p-3"
                                    />
                                </div>
                            </div>}

                            {props.multiLocale && <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    {t('form.add-bundle-group-description-translations')} <small>(Frontend)</small>
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    {props.multiLocale && <button
                                        className={`text-sm font-semibold mt-2 ${!inputs.locales?.length ? 'opacity-50' : 'hover:opacity-50 duration-200 cursor-pointer'}`}
                                        disabled={!inputs.locales?.length}
                                        onClick={(event) => openTranslationPopUp(event, 'description')}>
                                            + {t('form.add-bundle-group-description-translations-cta')}
                                    </button>}
                                </div>
                            </div>}
                            
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="bundleGroupBundle"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.bundle-group-bundle")} <span className="text-lg">*</span>
                                </label>

                                <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col">
                                    {inputs.bundleGroupBundle && (
                                        <div className="flex flex-1 block w-full focus:ring-black focus:border-black min-w-0 sm:text-sm whitespace-nowrap flex-wrap inline-flex gap-2 mb-3">
                                            {inputs.bundleGroupBundle.map((bundle) => (
                                                <div key={bundle.id}>
                                                    <span className="inline-flex items-center rounded-full bg-gray-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700 border border-gray-300 bg-white">
                                                        {bundle.name}
                                                        <button
                                                            type="button"
                                                            onClick={() => toggleInputsBundle(bundle)}
                                                            className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-500 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none"
                                                        >
                                                            <span className="sr-only">
                                                                Remove large option
                                                            </span>
                                                            <svg
                                                                className="h-2 w-2"
                                                                stroke="currentColor"
                                                                fill="none"
                                                                viewBox="0 0 8 8"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeWidth="1.5"
                                                                    d="M1 1l6 6m0-6L1 7"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    <select
                                        id="bundleGroupBundle"
                                        name="bundleGroupBundle"
                                        className="mt-1 block w-full text-base border-gray-300 focus:outline-none focus:ring-black focus:border-black sm:text-sm p-3"
                                        value=""
                                        onChange={({ target }) => {
                                            const selectedBundle = bundles.find(bundle => bundle.name === target.value);
                                            toggleInputsBundle(selectedBundle);
                                        }}
                                        disabled={inputs.bundleGroupBundle && inputs.bundleGroupBundle.length >= 3}
                                    >
                                        <option value="">{t("form.bundle-group-bundle-placeholder")}</option>
                                        {bundles.filter(bundle =>
                                            parseFloat(bundle.discount) > 0 &&
                                            (!inputs.bundleGroupBundle || !inputs.bundleGroupBundle.some(selectedBundle => selectedBundle.id === bundle.id))
                                        ).map((bundle, index) => (
                                            <option key={bundle.id} value={bundle.name}>
                                                {bundle.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="mb-5">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 pt-5 pt-14">
                                {bundleGroupType} {t("form.duration")}
                            </h3>
                            <p className="mt-1 max-w-2.5xl text-sm text-gray-500">
                                {t("form.bundle-group-duration-text")}
                            </p>
                        </div>

                        <div className="group bg-gray-100 px-8">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-5">
                                <label
                                    htmlFor="bundleGroupStartDate"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("table.start-date")} <span className="text-lg">*</span>
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        required
                                        type="date"
                                        name="bundleGroupStartDate"
                                        id="bundleGroupStartDate"
                                        value={inputs.bundleGroupStartDate || ""}
                                        onChange={handleChange}
                                        className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:max-w-xs sm:text-sm border-gray-300 p-3"
                                    />
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="bundleGroupEndDate"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("table.end-date")} <span className="text-lg">*</span>
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        required
                                        type="date"
                                        name="bundleGroupEndDate"
                                        id="bundleGroupEndDate"
                                        value={inputs.bundleGroupEndDate || ""}
                                        onChange={handleChange}
                                        className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:max-w-xs sm:text-sm border-gray-300 p-3"
                                    />
                                </div>
                            </div>
                        </div>

                        {props.edit ? (
                            <>
                                <div className="mb-5">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900 pt-14">
                                        {t("form.logs")}
                                    </h3>
                                    <p className="mt-1 max-w-2.5xl text-sm text-gray-500">
                                        {t("form.logs-bundle-group-text")}
                                    </p>
                                </div>

                                <div className="group bg-gray-100 px-8">
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-5">
                                        <label
                                            htmlFor="bundleGroupEndDate"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t("table.updated-by")}
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                required
                                                disabled
                                                type="text"
                                                value={inputs.bundleGroupUpdatedBy || ""}
                                                onChange={handleChange}
                                                className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:max-w-xs sm:text-sm border-gray-300 opacity-50 p-3"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                        <label
                                            htmlFor="bundleGroupEndDate"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t("table.updated-at")}
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                required
                                                disabled
                                                type="text"
                                                value={getUpdatedAtTimeString(inputs.bundleGroupUpdatedAt)}
                                                onChange={handleChange}
                                                className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:max-w-xs sm:text-sm border-gray-300 opacity-50 p-3"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        <div className="mb-5">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 pt-14">
                                {bundleGroupType} {t("form.publication")}
                            </h3>
                            <p className="mt-1 max-w-3xl text-sm text-gray-500">
                                {t("form.bundle-group-publication-text")}
                            </p>
                        </div>

                        <div className="group bg-gray-100 px-8">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-5">
                                <label
                                    htmlFor="bundleGroupPublished"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.published")}
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2 sm:pt-2 pt-3">
                                    <label
                                        htmlFor="publish-toggle"
                                        className="inline-flex relative items-center cursor-pointer"
                                    >
                                        <input
                                            onChange={handleChange}
                                            type="checkbox"
                                            name="bundleGroupPublished"
                                            checked={inputs.bundleGroupPublished || false}
                                            id="publish-toggle"
                                            className="sr-only peer"
                                        />
                                        <div className="w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-200 dark:peer-focus:ring-gray-200 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                                        <span className="ml-3 text-sm text-sm text-gray-500">
                                            {inputs.bundleGroupPublished
                                                ? t("form.published")
                                                : t("form.draft")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-5">
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="ml-3 inline-flex justify-center p-3 border border-transparent shadow-sm text-sm text-white bg-black hover:opacity-50 duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black relative">
                            {waitingResponse ? (
                                <div className="absolute animate-spin h-5 w-5 rounded-full border-2 border-white border-l-transparent" />
                            ) : null}
                            <span className={getCTATextClass()}>{getCTAText()} {bundleGroupType}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    ) : (
        <Error404 />
    );
};

const TranslationPopUp = props => {
    const [translations, setTranslations] = useState({})
    const [maxLengthAlert, setMaxLengthAlert] = useState([])
    const { t } = useTranslation("translation")

    useEffect(() => {
        setTranslations(props.translations || {})
    }, [props.translations])

    const TranslationPopUpTitles = {
        title: t("form.add-bundle-group-title-translations"),
        tag: t("form.add-bundle-group-tag-translations"),
        description: t("form.add-bundle-group-description-translations")
    }

    const onChange = ({ target }) => {
        if (target.value.length === 50) {
            setMaxLengthAlert([...maxLengthAlert, target.name.replace(`${props.contentType}-`, "")])
        } else {
            const newMaxLengthLocales = maxLengthAlert.filter(locale => locale !== target.name.replace(`${props.contentType}-`, ""))
            setMaxLengthAlert(newMaxLengthLocales)
        }

        setTranslations({
            ...translations,
            [target.name]: target.value,
        })
    }

    const onSave = (event) => {
        event.preventDefault()
        props.saveTranslations(translations)
        props.closePopUp()
    }
    const onClose = (event) => {
        event.preventDefault()
        props.closePopUp()
    }
    return (
        <div className="w-screen h-screen absolute top-0 left-0 bg-black z-50 flex items-center justify-center bg-opacity-60">
            <div className="bg-white py-7 flex flex-col w-5/12">
                <div className="mb-7 px-5">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {TranslationPopUpTitles[props.contentType] || t("form.default-title")}
                    </h3>
                    {props.contentType === 'title' && (
                        <small>{t("form.add-bundle-group-title-translations-help")}</small>
                    )}
                </div>
            <div className="max-h-96 px-5 overflow-y-scroll">
                {props.locales?.map((locale, index) => {
                    const inputId = `${props.contentType}-${locale}`
                    const value = translations[inputId] || ""
                        return (
                            <div key={index} className="flex justify-between items-center mb-2.5">
                                <label htmlFor={inputId} className="block text-sm font-medium text-gray-700 w-16">{locale}</label>
                                <div className="flex grow flex-col ml-5">
                                    <input
                                        maxLength="400"
                                        type="text"
                                        onChange={onChange} 
                                        value={value} 
                                        id={inputId} 
                                        name={inputId} 
                                        placeholder={`Bundle Group ${props.contentType.charAt(0).toUpperCase() + props.contentType.slice(1)}`}
                                        className="flex-1 block grow focus:ring-black focus:border-black min-w-0 sm:text-sm border-gray-300 p-3 false"
                                    />
                                    {maxLengthAlert.includes(locale) && (
                                        <span className="text-red-600 text-xs mt-1.5">{t("form.bundle-name-max-length")}</span>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="flex mt-7 px-5">
                    <button
                        onClick={onClose}
                        className="grow justify-center border border-gray-300 shadow-sm p-3 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:mt-0 sm:col-start-1 sm:text-sm mx-1 hover:opacity-75 text-center duration-200"
                    >
                        {t('form.alert.close')}
                    </button>
                    <button
                        onClick={onSave}
                        type="submit"
                        className="grow justify-center border border-transparent shadow-sm p-3 bg-black text-base font-medium text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:col-start-2 sm:text-sm mx-1 hover:opacity-75 text-center duration-200"
                    >
                        {t('form.alert.save')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BundleGroupForm;